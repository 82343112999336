/** ******************************************************************************
 * Copyright (C) 2017 TypeFox and others.
 *
 * This program and the accompanying materials are made available under the
 * terms of the Eclipse Public License v. 2.0 which is available at
 * http://www.eclipse.org/legal/epl-2.0.
 *
 * This Source Code may also be made available under the following Secondary
 * Licenses when the conditions for such availability set forth in the Eclipse
 * Public License v. 2.0 are satisfied: GNU General Public License, version 2
 * with the GNU Classpath Exception which is available at
 * https://www.gnu.org/software/classpath/license.html.
 *
 * SPDX-License-Identifier: EPL-2.0 OR GPL-2.0 WITH Classpath-exception-2.0
 ********************************************************************************/

import { OperatingSystem, isMacintosh, isWindows } from './platform';

export type CMD = [string, string[]];
export function cmd(command: string, ...args: string[]): CMD {
  return [isWindows ? 'cmd' : command, isWindows ? ['/c', command, ...args] : args];
}

export namespace OS {
  export enum Type {
    Windows = OperatingSystem.Windows,
    Linux = OperatingSystem.Linux,
    OSX = OperatingSystem.Macintosh,
  }

  export function type(): OperatingSystem {
    if (isWindows) {
      return OperatingSystem.Windows;
    }
    if (isMacintosh) {
      return OperatingSystem.Macintosh;
    }
    return OperatingSystem.Linux;
  }
}

export function isNodeIntegrated(): boolean {
  return typeof module !== 'undefined' && !!module.exports;
}

const safeGlobal: any = typeof global === 'undefined' ? (typeof window === 'undefined' ? {} : window) : global;

export function isElectronNode() {
  return typeof process !== 'undefined' && process.env && !!process.env.ELECTRON_RUN_AS_NODE;
}

export function isDevelopment() {
  return safeGlobal.isDev || (typeof process !== 'undefined' && process.env.IS_DEV);
}
