export const LANGUAGE_TO_SUFFIX: {
  [key: string]: string;
} = {
  abap: '.abap',
  bat: '.bat',
  bibtex: '.bib',
  c: '.c',
  clojure: '.clj',
  coffeescript: '.coffee',
  cpp: '.cpp',
  csharp: '.cs',
  css: '.css',
  'cuda-cpp': '.cu',
  dart: '.dart',
  diff: '.diff',
  dockerfile: '.dockerfile',
  fsharp: '.fs',
  go: '.go',
  groovy: '.groovy',
  haml: '.haml',
  handlebars: '.handlebars',
  hlsl: '.hlsl',
  html: '.html',
  ini: '.ini',
  jade: '.jade',
  java: '.java',
  javascript: '.js',
  javascriptreact: '.jsx',
  json: '.json',
  julia: '.jl',
  latex: '.tex',
  less: '.less',
  lua: '.lua',
  makefile: '.make',
  markdown: '.md',
  'objective-c': '.m',
  'objective-cpp': '.mm',
  perl: '.pl',
  perl6: '.6pl',
  php: '.php',
  plaintext: '.txt',
  powershell: '.ps1',
  pug: '.pug',
  python: '.py',
  r: '.r',
  razor: '.cshtml',
  ruby: '.rb',
  rust: '.rs',
  sass: '.sass',
  scss: '.scss',
  shaderlab: '.shader',
  shellscript: '.sh',
  slim: '.slim',
  sql: '.sql',
  stylus: '.styl',
  swift: '.swift',
  tex: '.tex',
  typescript: '.ts',
  typescriptreact: '.tsx',
  vb: '.vb',
  vue: '.vue',
  xml: '.xml',
  xsl: '.xsl',
  yaml: '.yaml',
};
