import { LOCALE_TYPES } from '@opensumi/ide-core-common/lib/const';

import { browserViews } from './contributes/zh-CN.lang';
import { editorLocalizations } from './editor/zh-CN';
import { zhCN as mergeConflicts } from './merge-conflicts/zh-CN.lang';

export const localizationBundle = {
  languageId: LOCALE_TYPES.ZH_CN,
  languageName: 'Chinese',
  localizedLanguageName: '中文(中国)',
  contents: {
    ButtonAllow: '允许',
    ButtonOK: '确定',
    ButtonCancel: '取消',
    'common.yes': '是',
    'common.no': '否',
    extension: '插件',
    editTtile: '编辑',
    'common.loading': '加载中...',

    'tree.filter.placeholder': '输入关键字或路径筛选',

    'file.new': '新建文件',
    'file.folder.new': '新建文件夹',
    'file.locate': '文件定位',

    'file.save': '保存文件',
    'file.saveAll': '保存全部',
    'file.autoSave': '自动保存',
    'file.open': '打开文件',
    'file.open.side': '侧边栏打开',
    'file.open.type': '打开方式...',
    'file.open.type.placeholder': '为 "{0}" 选择打开方式',
    'file.open.type.detail.active': '当前',
    'file.open.type.detail.and': '和',
    'file.open.type.detail.default': '默认',
    'file.open.type.preference.default': '为 "{0}" 配置默认编辑器...',
    'file.delete': '删除',
    'file.rename': '重命名',
    'file.compare': '与编辑器中的文件比较',
    'file.close': '关闭文件',
    'file.copy.path': '复制路径',
    'file.copy.relativepath': '复制相对路径',
    'file.paste.file': '粘贴',
    'file.copy.file': '复制',
    'file.cut.file': '剪切',
    'file.move.existMessage': '要粘贴的文件同时被删除或移动。无法移动/复制。文件已存在于目标位置。',
    'file.action.new.file': '新建文件',
    'file.action.new.folder': '新建文件夹',
    'file.action.refresh': '刷新文件',
    'file.action.collapse': '全部折叠',
    'file.location': '在文件树中定位',
    'file.open.folder': '打开文件夹',
    'file.open.workspace': '从文件打开工作区',
    'file.confirm.delete': '确定删除下面列的文件?\n{0}',
    'file.confirm.delete.ok': '移入回收站',
    'file.confirm.delete.cancel': '取消',
    'file.confirm.move': '确定移动文件 {0} 到 {1} ?',
    'file.confirm.move.ok': '移动',
    'file.confirm.move.cancel': '取消',
    'file.confirm.paste': '确定拷贝文件 {0} 到 {1} ?',
    'file.confirm.paste.ok': '拷贝',
    'file.confirm.paste.cancel': '取消',
    'file.confirm.replace': '确定替换{0}文件?',
    'file.confirm.replace.ok': '替换',
    'file.confirm.replace.cancel': '取消',
    'file.empty.defaultTitle': '无打开的文件夹',
    'file.empty.defaultMessage': '尚未打开文件夹',
    'file.workspace.defaultTip': '无标题（工作区）',
    'file.workspace.defaultWorkspaceTip': '{0}（工作区）',
    'file.empty.openFolder': '打开文件夹',
    'file.collapse': '全部折叠',
    'file.refresh': '刷新',
    'file.search.folder': '在文件夹中查找',
    'file.focus.files': '在资源管理器中聚焦文件',
    'file.filetree.filter': '基于当前文件树筛选文件',
    'file.filetree.openTerminalWithPath': '在终端中打开',
    'file.tooltip.symbolicLink': '符号链接',
    'file.resource-deleted': '（已删除）',
    'file.revealInExplorer': '在文件树中定位',

    'menu-bar.title.file': '文件',
    'menu-bar.title.edit': '编辑',
    'menu-bar.title.selection': '选择',
    'menu-bar.title.view': '视图',
    'menu-bar.title.go': '前往',
    'menu-bar.title.debug': '调试',
    'menu-bar.title.terminal': '终端',
    'menu-bar.title.window': '窗口',
    'menu-bar.title.help': '帮助',

    'menu-bar.file.open': '打开文件',
    'menu-bar.file.save-as': '另存为',
    'menu-bar.file.save-all': '全部保存',
    'menu-bar.view.quick.command': '命令面板',

    'menu-bar.go.jumpToBracket': '跳转到括号',
    'menu-bar.go.nextProblemInFiles': '下一个问题',
    'menu-bar.go.prevProblemInFiles': '上一个问题',

    'menu-bar.file.pref': '设置',
    'menu-bar.mode.compact': '紧凑模式',

    editor: '编辑器',
    'editor.undo': '撤销',
    'editor.redo': '重做',
    'editor.saveCurrent': '保存当前文件',
    'editor.title.context.close': '关闭',
    'editor.closeTab.title': '关闭 ({0})',
    'editor.closeCurrent': '关闭当前编辑窗口',
    'editor.openExternal': '使用外部软件打开',
    'editor.cannotOpenBinary': '无法在编辑器中展示二进制文件。',
    'editor.splitToLeft': '向左拆分',
    'editor.splitToRight': '向右拆分',
    'editor.splitToTop': '向上拆分',
    'editor.splitToBottom': '向下拆分',
    'editor.closeAllInGroup': '关闭全部',
    'editor.closeSaved': '关闭已保存',
    'editor.closeOtherEditors': '关闭其他',
    'editor.closeToRight': '关闭到右侧',
    'editor.cannotSaveWithoutDirectory': '请选择要保存的路径',
    'editor.failToOpen': '打开文件 {0} 失败, 请确认文件是否存在。详细错误信息: {1}',
    'editor.lineHeight': '行高',
    'editor.lineHeight.description':
      '控制行高。\n\n - 使用 0 根据字号自动计算行高。\n\n - 介于 0 和 8 之间的值将用作字号的乘数。\n\n - 大于 8 的值将用作有效值。',
    'editor.copyPath': '复制路径',
    'editor.copyRelativePath': '复制相对路径',

    'editor.workspaceSymbol.quickopen': '搜索工作区符号',
    'editor.workspaceSymbolClass.quickopen': '搜索工作区类',
    'editor.workspaceSymbol.description': '跳转工作区符号',
    'editor.workspaceSymbol.search': '请输入要搜索的符号',
    'editor.workspaceSymbolClass.search': '请输入要搜索的类',
    'editor.workspaceSymbol.notfound': '没有匹配的符号',
    'editor.workspaceSymbolClass.notfound': '没有匹配的类',

    'edit.cut': '剪切',
    'edit.copy': '复制',
    'edit.paste': '粘贴',
    'edit.selectAll': '全选',

    'editor.goBack': '向后',
    'editor.goForward': '前进',
    'editor.quickOpen': '转到文件...',
    'editor.editor.goToSymbol': '转到符号...',
    'editor.goToLine': '转到行...',
    'editor.openType': '打开方式',

    'quickopen.goToLine.defaultMessage': '当前行: {0}， 列: {1}。 请输入要转到的行号： 1 至 {2} 之间的数字。',
    'quickopen.goToLine.notValid': '请打开一个有效的编辑器。',
    'quickopen.goToLine.lineInfo': '转到行: {0}',
    'quickopen.goToLine.colInfo': '，列: {0}',
    'quickopen.goToLine.desc': '转到行...',

    'quickopen.command.placeholder': '请输入你要执行的命令',
    'quickopen.command.description': '执行 IDE 预设的命令',
    'quickopen.command.nohandler': '找不到结果',
    'quickopen.quickinput.prompt': "请按 'Enter' 确认或 'Esc' 取消",
    'quickopen.tab.file': '文件',
    'quickopen.tab.class': '类',
    'quickopen.tab.symbol': '符号',
    'quickopen.tab.command': '指令',
    'quickopen.tab.goToLine': '转到行',
    'quickopen.tab.tip.prefix': '按',
    'quickopen.tab.tip.suffix': '切换搜索类别',
    'quickOpen.openOnTheRightSide': '在侧边打开',

    'explorer.title': '资源管理器',

    'explorer.electron.revealInFinder': '在文件系统中打开',
    'explorer.electron.openInSystemTerminal': '在系统终端中打开',
    'explorer.electron.openInSystemTerminal.error': '在系统终端中打开 {0} 失败: {1}',

    'search.title': '跨文件搜索',
    'search.input.placeholder': '输入搜索内容',
    'search.input.title': '搜索',
    'search.replace.title': '替换',
    'search.input.checkbox': '显示搜索条件',
    'file-search.command.fileOpen.description': '打开文件',
    'file-search.command.fileOpen.placeholder': '按名称搜索文件（追加:转到行或追加 @ 转到符号）',
    'search.includes': '要包含的文件',
    'search.includes.description': '输入文件名或文件夹名，多个用 ", " 分隔',
    'search.excludes': '要排除的文件',
    'search.excludes.default.enable': '启用默认排除项',
    'search.replaceAll.label': '替换全部',
    'search.replace.label': '替换为',
    'search.files.result': '找到 {1} 个文件，共 {0} 个结果',
    'search.CollapseDeepestExpandedLevelAction.label': '全部折叠',
    'search.ExpandDeepestExpandedLevelAction.label': '全部展开',
    'search.ClearSearchResultsAction.label': '清空搜索结果',
    'search.RefreshAction.label': '刷新',
    'search.removeAll.occurrences.files.confirmation.message': '确定替换 {1} 个文件中的 {0} 个结果吗?',
    'search.removeAll.occurrences.file.confirmation.message': '确定替换该文件中的 {0} 个结果吗?',
    'search.result.hide': '隐藏',
    'search.menu.copyAll': '全部复制',
    'search.help.showIncludeRule': '查看语法规则',
    'search.help.supportRule': '支持以下语法规则：',
    'search.help.excludeList': '已排除项包含：',
    'search.help.concatRule': '多个条件分隔',
    'search.help.matchOneOrMoreRule': '匹配路径段中的一个或多个字符',
    'search.help.matchOne': '匹配路径段中的一个字符',
    'search.help.matchAny': '匹配任意数量的路径段，包括无',
    'search.help.matchWithGroup': '组合条件',
    'search.help.matchRange': '匹配的字符范围',
    'search.help.modify': '修改',
    'search.replace.buttonOK': '替换',
    'search.replace.buttonCancel': '取消',
    'search.too.many.results': '由于您的搜索条件过于疏松，我们仅展示了部分搜索结果',
    'search.fileReplaceChanges': '{0} ↔ {1} (替换预览)',
    'search.fileResults': '文件结果',
    'search.fileResults.notfound': '没有匹配的文件',
    'search.fileSymbolResults': '符号 ({0})',
    'search.fileSymbolResults.notfound': '没有匹配的符号',
    'search.historyMatches': '最近打开',
    'search.replaceAll.occurrencesMessage': '已将 {1} 个文件中出现的 {0} 处替换为 “{2}”。',
    'search.replace.toggle.button.title': '切换替换',
    'search.caseDescription': '区分大小写',
    'search.wordsDescription': '全字匹配',
    'search.regexDescription': '使用正则表达式',
    'search.includeIgnoredFiles': '包含忽略的文件',
    'search.onlyOpenEditors': '仅在已打开的编辑器内搜索',
    'search.noResultsFound': '未找到结果。查看您的设置配置排除, 并检查您的 gitignore 文件',

    'quickopen.recent-commands': '最近使用',
    'quickopen.other-commands': '其他命令',
    'quickopen.commands.notfound': '没有匹配的指令',
    mSelection: '选择',
    'selection.all': '选择全部',

    'dialog.confirm': '知道了',
    'dialog.file.name': '文件名',
    'dialog.file.title': '存储目录：',
    'dialog.file.openLabel': '打开文件：',
    'dialog.file.saveLabel': '保存文件：',
    'dialog.file.close': '关闭',
    'dialog.file.ok': '确定',
    'dialog.ensure': '确定',
    'editorOpenType.code': '代码',
    'editorOpenType.preview': '预览',

    'scm.title': '源代码管理',
    'scm.action.git.refresh': '刷新',
    'scm.action.git.commit': '提交',
    'scm.action.git.more': '更多操作...',
    'scm.statusbar.repo': '当前仓库',
    'scm.provider.title': '代码仓库列表',
    'scm.provider.empty': '没有可用的源代码仓库',
    'scm.provider.init': '点击初始化仓库',
    'scm.diff.change.next': '下一个变化',
    'scm.diff.change.previous': '上一个变化',
    'scm.diff.toggle.renderSideBySide': '切换内联视图',
    'scm.dirtyDiff.changes': '第 {0} 个更改 (共 {1} 个)',

    'debug.action.start': '启动调试',
    'debug.action.no.configuration': '暂无配置',
    'debug.action.add.smartAddConfiguration': '智能添加配置',
    'debug.action.add.configuration': '添加配置',
    'debug.action.edit.configuration': '编辑配置',
    'debug.action.open.launch.editor': '使用 UI 模式编辑',
    'debug.action.open.configuration': '打开 launch.json',
    'debug.action.debug.console': '调试控制台',
    'debug.action.step-into': '单步跳入',
    'debug.action.step-over': '单步跳过',
    'debug.action.step-out': '单步跳出',
    'debug.action.restart': '重启',
    'debug.action.pause': '暂停',
    'debug.action.stop': '终止',
    'debug.action.disattach': '断开',
    'debug.action.continue': '下一步',
    'debug.action.runToCursor': '运行到光标处',
    'debug.action.forceRunToCursor': '强制运行到光标处',
    'debug.console.panel.title': '调试控制台',
    'debug.console.panel.default': '默认',
    'debug.console.filter.placeholder': '筛选器(例如 text、!exclude)',
    'debug.console.clear': '清空日志',
    'debug.console.copy': '复制',
    'debug.console.copyAll': '复制全部',
    'debug.console.collapseAll': '折叠全部',
    'debug.console.followLink': '按住 {0} 并单击可访问链接',
    'debug.console.errorMessage': '调试进程初始化异常，请打开控制面板查看错误日志',
    'debug.console.input.placeholder': '请发起调试会话来对表达式求值',
    'debug.console.consoleCleared': '输出已清理',
    'debug.notSupported.type': '调试类型 "{0}" 不是支持的调试类型，请检查配置或安装对应调试插件',
    'debug.notSupported.any': '当前调试配置不支持，请检查配置或安装对应调试插件',

    'output.tabbar.title': '输出',
    'output.channel.none': '<还没有任何输出>',
    'output.configurationTitle': '输出配置',
    'output.logWhenNoPanel': '无面板时是否输出到浏览器 console',
    'output.maxChannelLine': '限制输出最大行数',
    'output.maxChannelLineDesc': '限制单个输出通道最大行数，超出后会自动裁剪',
    'output.enableLogHighlight': '输出文本语法高亮',
    'output.enableLogHighlightDesc': '对输出文本使用 .log 语法高亮解析。',
    'output.enableSmartScroll': '输出视图智能滚动',
    'output.enableSmartScrollDesc': '在点击输出视图时禁用滚动，点击最后一行输出时启用滚动。',

    'debug.stack.loadMore': '加载更多堆栈',
    'debug.stack.loading': '加载中...',
    'debug.stack.showMoreAndOrigin': '展示 {0} 项: {1}',
    'debug.breakpoint.deactive': '关闭所有断点',
    'debug.breakpoint.active': '激活所有断点',
    'debug.threads.title': '进程',
    'debug.watch.title': '监视',
    'debug.callStack.title': '堆栈',
    'debug.variables.title': '变量',
    'debug.variables.view.memory': '查看二进制视图',
    'debug.variables.view.memory.prompt.hexEditor.notInstalled': '查看二进制视图需要安装 Hex Editor 扩展',
    'debug.breakpoints.title': '断点',
    'debug.container.title': '调试管理器',
    'debug.breakpoint.toggle': '启用/禁用断点',
    'debug.breakpoint.removeAll': '移除所有断点',
    'debug.breakpoint.uncaughtExceptions': '未捕获的异常',
    'debug.breakpoint.allExceptions': '所有异常',
    'debug.watch.removeAll': '移除所有表达式',
    'debug.watch.collapseAll': '折叠所有表达式',
    'debug.watch.add': '添加表达式',
    'debug.watch.edit': '编辑表达式',
    'debug.watch.copyValue': '复制值',
    'debug.watch.remove': '删除表达式',
    'debug.watch.notAvailable': '不可用',
    'debug.breakpoint.breakpointMessage': '断点',
    'debug.breakpoint.logpointMessage': '日志断点',
    'debug.breakpoint.conditionalMessage': '条件断点',
    'debug.breakpoint.unverified': '无效的',
    'debug.breakpoint.disabled': '禁用的',
    'debug.breakpoint.blank': '空行',
    'debug.configuration.selectAutomaticDebugTypesHint': '自动生成的调试配置',
    'debug.configuration.selectAutomaticDebugTypes': '选择调试类型',
    'debug.configuration.selectAutomaticDebugConfiguration': '选择调试配置',
    'debug.configuration.comment1': '使用 IntelliSense 了解相关属性。 ',
    'debug.configuration.comment2': '悬停以查看现有属性的描述。',
    'debug.configuration.comment3': '欲了解更多信息，请访问: https://go.microsoft.com/fwlink/?linkid=830387',
    'debug.launch.existed': '调试配置"{0}"已在运行',
    'debug.expression.log.placeholder':
      '遇到断点时要记录的消息。 {}中的表达式是插值的。. 按 "Enter" 键确认，"Esc" 键取消。',
    'debug.expression.hit.placeholder': '满足命中次数条件时中断。 按 "Enter" 键确认，"Esc" 键取消。',
    'debug.expression.condition.placeholder': '在表达式结果为真时中断。按 "Enter" 键确认，"Esc" 键取消。',
    'debug.expression.condition': '表达式',
    'debug.expression.hitCondition': '命中次数',
    'debug.expression.logMessage': '日志消息',
    'debug.menu.delete.breakpoint': '删除断点',
    'debug.menu.edit.breakpoint': '编辑断点',
    'debug.menu.disable.breakpoint': '禁用断点',
    'debug.menu.enable.breakpoint': '启用断点',
    'debug.menu.add.logpoint': '添加记录点',
    'debug.menu.add.conditional': '添加条件断点',
    'debug.menu.add.breakpoint': '添加断点',
    'debug.menu.disable.logpoint': '禁用记录点',
    'debug.menu.title.run': '运行或调试...',
    'debug.stack.frame.noSource': '未知源',
    'debug.stack.frame.because': '因',
    'debug.stack.frame.stopped': '已暂停',
    'debug.stack.frame.running': '正在运行',
    'debug.launch.configurations.debugType': '配置类型。',
    'debug.launch.configurations.debugTypeNotRecognised': '无法识别此调试类型。确保已经安装并启用相应的调试扩展。',
    'debug.launch.configurations.node2NotSupported':
      "不再支持 'node2'，改用 'node'，并将 'protocol' 属性设为 'inspector'。",
    'debug.launch.configurations.debugName': '配置名称；在启动配置下拉菜单中显示。',
    'debug.launch.configurations.debugRequest': '请求配置类型。可以是 "启动" 或 "附加"。',
    'debug.launch.configurations.debugServer':
      '仅用于调试扩展开发: 如果已指定端口，VS 代码会尝试连接到在服务器模式中运行的调试适配器',
    'debug.launch.configurations.debugPrelaunchTask': '调试会话开始前要运行的任务。',
    'debug.launch.configurations.debugPostDebugTask': '调试会话结束后运行的任务。',
    'debug.launch.configurations.debugWindowsConfiguration': '特定于 Windows 的启动配置属性。',
    'debug.launch.configurations.debugOSXConfiguration': '特定于 OS X 的启动配置属性。',
    'debug.launch.configurations.debugLinuxConfiguration': '特定于 Linux 的启动配置属性。',
    'debug.launch.typeNotSupported': '调试类型 "{0}" 不支持',
    'debug.launch.catchError': '启动调试进程时遇到了错误, 请检查调试控制台',
    'debug.launch.view.template.button.addItem': '添加一项',
    'debug.launch.view.template.input.placeholder': '请输入 {0}',
    'debug.launch.view.template.button.submit': '新增配置项',
    'debug.launch.view.edit.inLaunchJson': '在 launch.json 中编辑',

    'debug.widget.exception.thrownWithId': '发生异常: {0}',
    'debug.widget.exception.thrown': '出现异常。',

    'workspace.openDirectory': '打开文件夹',
    'workspace.addFolderToWorkspace': '将文件夹添加到工作区...',
    'workspace.removeFolderFromWorkspace': '从工作区移除文件夹',
    'workspace.saveWorkspaceAsFile': '将工作区另存为...',
    'workspace.openWorkspaceTitle': '工作区',
    'workspace.openWorkspace': '打开工作区',

    'window.toggleDevTools': '切换开发人员工具',
    'window.reload': '重载窗口',

    'preference.menubar.mode.compact': '控制菜单栏是否使用紧凑模式',

    'preference.explorer.confirm.delete': '控制是否在删除文件时弹出确认框',
    'preference.explorer.confirm.move': '控制是否在移动文件时弹出确认框',
    'preference.files.watcherExclude':
      '配置排除文件监听的文件路径 glob 模式，模式必须在绝对路径上匹配（即前缀为 ** 或完整路径才能正确匹配）。更改此设置需要重新启动。若在启动时遇到代码占用大量 CPU 时间，可以排除大文件夹以减少初始加载。',
    'preference.files.exclude':
      '配置排除的文件和文件夹的 glob 模式，资源管理器将根据此设置决定要显示或隐藏的文件和文件夹。',
    'preference.search.exclude':
      '配置在搜索中排除的文件和文件夹的 glob 模式。已经继承 `#files.exclude#` 设置的所有 glob 模式。',
    'preference.search.include': '配置在搜索中包含的文件和文件夹的 glob 模式',
    'preference.files.watcherExclude.title': '排除文件监听',
    'preference.search.exclude.title': '搜索排除文件',
    'preference.search.useReplacePreview': '控制搜索替换打开编辑器时，是否打开“替换预览”。',
    'preference.search.searchOnType': '控制是否在搜索框中输入时自动搜索。',
    'preference.search.searchOnTypeDebouncePeriod': '控制输入时自动搜索的延迟时间（毫秒）。',
    'preference.files.exclude.title': '排除文件显示',
    'preference.debug.internalConsoleOptions': '控制何时打开内部调试控制台。',
    'preference.debug.openDebug': '控制何时打开调试视图。',
    'preference.debug.debugViewLocation': '控制调试视图的位置。',
    'preference.debug.trace': '启用/禁用与调试适配器的堆栈通信。',
    'preference.debug.allowBreakpointsEverywhere': '允许在所有文件中设置断点。',
    'preference.debug.toolbar.float': '浮层模式',
    'preference.files.associations.title': '文件关联',
    'preference.files.associations':
      '配置语言的文件关联（如 "*.extension": "html"）。这些关联的优先级高于已安装语言的默认关联。',
    'preference.files.encoding.title': '文件编码',
    'preference.files.encoding': '读写文件时使用的默认字符集编码',
    'preference.files.autoGuessEncoding': '启用后，编辑器将在打开文件时尝试猜测字符集编码。也可以按语言配置此设置。',
    'preference.files.autoGuessEncoding.title': '自动猜测编码',
    'preference.explorer.fileTree.indent.title': '资源管理器：文件树子节点缩进',
    'preference.explorer.fileTree.baseIndent.title': '资源管理器：文件树基础缩进',
    'preference.debug.toolbar.float.title': '运行与调试：浮层模式',
    'preference.debug.breakpoint.editorHint.title': '控制是否开启编辑器断点闪烁提示',
    'preference.debug.breakpoint.editorHint': '启用后，点击断点列表跳转到编辑器时，会有背景色闪烁提示',
    'preference.debug.breakpoint.showBreakpointsInOverviewRuler': '控制是否应在概览标尺中显示断点',

    'preference.debug.console.filter.mode': '调试控制台筛选器模式',
    'preference.debug.console.filter.mode.filter': '过滤模式',
    'preference.debug.console.filter.mode.matcher': '匹配模式',
    'preference.debug.console.wordWrap': '控制是否应在调试控制台中换行',
    'preference.debug.inline.values': '当处于调试过程中时，在编辑器中内联显示变量值',
    'preference.explorer.compactFolders':
      '文件夹紧凑模式下，单个子文件夹将被压缩在组合的树元素中，对 Java 包结构很有用。',
    'preference.explorer.compactFolders.title': '资源管理器：文件夹紧凑模式',
    'preference.explorer.autoReveal': '打开文件时自动显示并聚焦',
    'preference.editorTitle.openSource': '打开设置（JSON）',
    'preference.editorTitle.openUserSource': '打开全局设置（JSON）',
    'preference.editorTitle.openWorkspaceSource': '打开工作区设置（JSON）',
    'preference.editorTitle.openPreference': '打开设置（可视化）',
    'preference.view.saveLayoutWithWorkspace.title': '控制是否将布局信息与工作区绑定',
    'preference.stringArray.operate.delete': '删除',
    'preference.stringArray.operate.edit': '修改',
    'preference.stringArray.operate.editTip': '按回车确认',
    'preference.stringArray.none': '暂无可选值',

    // 设置中枚举选项的默认值提示信息
    'preference.enum.default': '默认值',

    // 终端
    'preference.terminal.type': '默认终端类型',
    'preference.terminal.typeDesc': '控制打开终端所使用的默认 Shell 类型。',
    'preference.terminal.fontFamily': '终端字体',
    'preference.terminal.fontSize': '终端字体大小',
    'preference.terminal.fontWeight': '终端字体权重',
    'preference.terminal.lineHeight': '终端文字行高',
    'preference.terminal.cursorBlink': '终端光标是否闪烁',
    'preference.terminal.scrollback': '终端可滚动内容行数',
    'preference.terminal.integrated.shellArgs.linux': 'Linux 终端命令行参数',
    'preference.terminal.integrated.shellArgs.linuxDesc': '配置在 Linux 终端上时要使用的命令行参数。',

    'preference.terminal.integrated.copyOnSelection': '终端选中复制',
    'preference.terminal.integrated.copyOnSelectionDesc': '将终端中选中的文本立即复制到剪贴板。',

    'preference.terminal.integrated.localEchoEnabled': '终端本地回显',
    'preference.terminal.integrated.localEchoDesc': '何时应启用本地回显',
    'preference.terminal.integrated.localEchoLatencyThreshold': '终端本地回显触发延时',
    'preference.terminal.integrated.localEchoLatencyThresholdDesc':
      "网络延迟的长度(以毫秒为单位)，其中本地编辑将在终端上回显，无需等待服务器承认。如果为 '0'，则本地回显将始终开启，如果为 '-1'，则将禁用。",
    'preference.terminal.integrated.localEchoExcludePrograms': '终端本地回显排除的应用程序',
    'preference.terminal.integrated.localEchoExcludeProgramsDesc':
      '当在终端标题中找到其中一个程序名称时，将禁用本地回显。',
    'preference.terminal.integrated.localEchoStyle': '本地回显字体样式',
    'preference.terminal.integrated.localEchoStyleDesc': '本地回显文本的终端样式；字体样式或 RGB 颜色。',
    'preference.terminal.integrated.xtermRenderType': 'Xterm 渲染类型',
    'preference.terminal.integrated.xtermRenderTypeDesc': '选择 Xterm 渲染类型，WebGL 性能更强，Canvas 兼容性更佳。',
    'preference.terminal.integrated.enablePersistentSessionDesc': '窗口重载时持久化终端会话',
    'preference.terminal.integrated.cursorStyle': '终端输入指针样式',
    'preference.terminal.integrated.cursorStyleDesc': '修改终端输入指针样式',
    'settings.group.general': '常规',
    'settings.group.shortcut': '快捷键',
    'settings.group.editor': '编辑器',
    'settings.group.extension': '扩展',
    'settings.group.feature': '功能',
    'settings.group.terminal': '终端',
    'settings.group.view': '视图',

    'preference.general.theme': '主题',
    'preference.general.icon': '文件图标主题',
    'preference.general.productIconTheme': '产品图标主题',
    'preference.workbench.colorCustomizations': '覆盖当前所选颜色主题的颜色',
    'preference.general.language': '语言',
    'preference.general.language.change.refresh.info': '更改语言后需重启后生效，是否立即刷新?',
    'preference.general.language.change.refresh.now': '立即刷新',
    'preference.general.language.change.refresh.later': '稍后自己刷新',

    // workbench
    'preference.workbench.refactoringChanges.showPreviewStrategy': '触发一些重构变更时，是否需要弹窗确认',
    'preference.workbench.refactoringChanges.showPreviewStrategy.title': '重构确认方式',
    'preference.editor.wrapTab': '编辑器 Tab 自动换行',
    'preference.editor.fontFamily': '字体',
    'preference.editor.minimap': '显示 Minimap',
    'preference.editor.forceReadOnly': '只读模式',
    'preference.editor.renderLineHighlight': '当前行突出显示',
    'preference.editor.fontWeight': '字体粗细',
    'preference.editor.fontSize': '字体大小',
    'preference.editor.tabSize': 'Tab 缩进大小',
    'preference.editor.detectIndentation': '自动检测缩进大小',
    'preference.editor.renderWhitespace': '显示空白字符',
    'preference.editor.cursorStyle': '光标样式',
    'preference.editor.insertSpace': '使用空格代替 Tab',
    'preference.editor.wordWrap': '自动换行',
    'preference.editor.wordWrapColumn': '自动换行长度',
    'preference.editor.askIfDiff': '保存文件冲突时提示',
    'preference.editor.readonlyFiles': '只读文件列表',
    'preference.editor.formatOnSaveTimeout': '格式化超时时间',
    'preference.editor.formatOnSaveTimeoutError': '格式化时间超过 {0} 毫秒，终止格式化',
    'preference.editor.autoSave': '自动保存文件',
    'preference.editor.autoSaveDelay': '自动保存延迟',
    'preference.editor.maxTokenizationLineLength': '最大解析标识长度',
    'preference.editor.quickSuggestionsDelay': '智能提示延迟（毫秒）',
    'preference.editor.largeFile': '超大文件尺寸',
    'preference.files.eol': '文件行尾字符',
    'preference.files.trimFinalNewlines': '移除最后的换行符',
    'preference.files.trimTrailingWhitespace': '移除结尾空格',
    'preference.files.insertFinalNewline': '结尾处插入空行',
    'preference.editor.formatOnPaste': '粘贴时自动格式化',
    'preference.editor.preferredFormatter': '默认格式化器',
    'preference.editor.bracketPairColorization.enabled': '括号着色',
    'preference.array.additem': '添加',
    'preference.editor.lineHeight': '行高',
    'preference.editor.saveCodeActions': '保存时运行的代码操作类型',
    'preference.editor.saveCodeActionsNotification': '代码操作执行时是否展示通知信息',

    'preference.item.notValid': '{0} 不是有效选项',

    'editor.saveAll': '保存全部',
    'editor.saveCodeActions.getting': '从 {0} 中获取 CodeAction',
    'editor.saveCodeActions.saving': '保存 "{0}"',

    'editor.autoSave.enum.off': '不启用',
    'editor.files.eol': '文件行尾字符',
    'editor.files.eolDesc': '控制文件默认行尾字符。',
    'editor.autoSave.enum.editorFocusChange': '编辑器失去焦点时自动保存',
    'editor.autoSave.enum.afterDelay': '编辑后自动保存（延迟一定时间）',
    'editor.autoSave.enum.windowLostFocus': '窗口失去焦点时自动保存',
    'editor.action.accept': '接受更改（使用右边)',
    'editor.action.revert': '回退更改（使用左边)',
    'editor.format.chooseFormatter': '选择要使用的格式化扩展。',
    'editor.formatDocument.label.multiple': '使用...格式化文档',
    'editor.formatSelection.label.multiple': '格式化选定内容的方式...',
    'editor.chooseEncoding': '用编码重新打开(会丢失未保存内容)',
    'editor.guessEncodingFromContent': '通过内容猜测',
    'editor.changeEol': '选择行尾序列',
    'editor.changeLanguageId': '选择语言模式',
    'editor.tokenize.test': '获取选中字符串的Tokenize结果(console)',

    'editor.largeFile.prevent': '文件过大，继续打开可能会导致卡顿或者崩溃。',
    'editor.file.prevent.stillOpen': '仍要打开',
    'editor.closeEditorsInOtherGroups': '关闭其他组中的编辑器',
    'editor.resetEditorGroups': '重置编辑器组大小',
    'editor.revert': '还原文档',

    'preference.diffEditor.renderSideBySide': '显示并排差异编辑器',
    'preference.diffEditor.ignoreTrimWhitespace': '忽略差异编辑器的前导和尾随空白字符',

    'preference.mergeEditor.autoApplyNonConflictChanges': '自动合并非冲突变更',

    'status.editor.chooseLanguage': '选择语言模式',
    'status.editor.goToLineCol': '转到行/列',
    'status.editor.chooseEncoding': '选择编码',
    'status.editor.changeEol': '选择行尾序列',

    'common.preference.open': '设置',
    'common.keymaps.open': '快捷键',
    'common.about': '关于',
    'common.find': '查找',
    'common.replace': '替换',
    'common.remoteMode': '远程模式',

    'component.message.origin': '来源',

    'component.modal.okText': '确定',
    'component.modal.cancelText': '取消',
    'component.modal.justOkText': '知道了',

    'preference.tab.user': '全局设置',
    'preference.tab.workspace': '工作区设置',

    'preference.tab.name': '设置',
    'preference.noResults': "未找到包含 '{0}' 的设置项",
    'preference.empty': '加载设置项...',
    'preference.editSettingsJson': '在 settings.json 中编辑',
    'preference.overwritten': '（已被下一级设置覆盖）',
    'preference.overwrittenInUser': '（已在全局设置中设置）',
    'preference.overwrittenInWorkspace': '（已在工作区设置中设置）',
    'preference.searchPlaceholder': '搜索设置...',
    'keymaps.tab.name': '快捷键设置',

    'marketplace.extension.update.now': '是，现在更新',
    'marketplace.extension.update.delay': '稍后我自己更新',
    'marketplace.extension.uninstall.failed': '卸载失败',
    'marketplace.extension.uninstall.failed.depended': '无法卸载扩展程序 "{0}"。 扩展程序 "{1}" 依赖于此',
    'marketplace.extension.disabled.failed.depended': '无法禁用扩展 "{0}"。扩展 "{1}" 依赖于此。',
    'marketplace.extension.reload.delay': '稍后我自己重启',
    'marketplace.extension.reload.now': '是，现在重启',
    'marketplace.extension.enable': '启用',
    'marketplace.extension.disable': '禁用',
    'marketplace.extension.enable.workspace': '启用(工作空间)',
    'marketplace.extension.disable.workspace': '禁用(工作空间)',
    'marketplace.extension.enable.all': '启用所有插件',
    'marketplace.extension.disable.all': '禁用所有插件',
    'marketplace.extension.builtin': '内置',
    'marketplace.extension.development': '开发模式',
    'marketplace.extension.install': '安装',
    'marketplace.extension.container': '扩展市场',
    'marketplace.extension.empty.disabled': '暂无已禁用的扩展',
    'marketplace.extension.notfound': '找不到扩展',
    'marketplace.panel.hot': '热门插件',
    'marketplace.panel.search': '搜索',
    'marketplace.panel.enabled': '已启用',
    'marketplace.panel.disabled': '已禁用',
    'marketplace.panel.tab.marketplace': '扩展市场',
    'marketplace.panel.tab.placeholder.search': '搜索市场中的扩展',
    'marketplace.panel.tab.placeholder.installed': '搜索已安装的扩展',
    'marketplace.tab.installed': '已安装的扩展',
    'marketplace.extension.findUpdate': '发现插件 {0} 有最新版本 {1}，是否要更新到最新版本？',
    'marketplace.extension.updateAll': '发现有多个扩展可以更新，是否一键全部更新？',
    'marketplace.extension.needreloadFromAll': '所有扩展更新完毕，重启后生效，是否立即重启？',
    'marketplace.extension.needreload': '插件 {0} 更新成功，重启后生效，是否立即重启？',
    'marketplace.extension.canupdate': '有新版本',
    'marketplace.extension.updating': '更新中',
    'marketplace.extension.update': '更新',
    'marketplace.extension.installing': '安装中',
    'marketplace.extension.reloadrequire': '需要重启',
    'marketplace.extension.uninstalling': '卸载中',
    'marketplace.extension.uninstall': '卸载',
    'marketplace.extension.uninstalled': '已卸载',
    'marketplace.extension.readme': '简介',
    'marketplace.extension.changelog': '更改日志',
    'marketplace.extension.dependencies': '依赖项',
    'marketplace.extension.installed': '已安装',
    'marketplace.extension.otherVersion': '安装其他版本',
    'marketplace.extension.currentVersion': '当前版本',
    'marketplace.quickopen.install': '安装插件',
    'marketplace.quickopen.install.byReleaseId': '安装插件 (ReleaseId)',
    'marketplace.quickopen.install.releaseId': '扩展 ReleaseId',
    'marketplace.quickopen.install.releaseId.required': '扩展 ReleaseId 必填',
    'marketplace.quickopen.install.id': '扩展 ID',
    'marketplace.quickopen.install.id.required': '扩展 ID 必填',
    'marketplace.quickopen.install.version.required': '扩展版本必填',
    'marketplace.quickopen.install.version.placeholder': '扩展版本',
    'marketplace.quickopen.install.error': '安装插件报错',

    'validate.tree.emptyFileNameError': '需提供可用的文件或文件夹名称',
    'validate.tree.fileNameStartsWithSlashError': '文件或文件夹名称不能以/开头',
    'validate.tree.fileNameFollowOrStartWithSpaceWarning': '在文件或文件夹名称中检查到前导或尾随空格',
    'validate.tree.fileNameExistsError': '文件或文件夹 **{0}** 已存在. 请使用另外的名称.',
    'validate.tree.invalidFileNameError': '名称 **{0}** 不可用. 请使用另外的名称.',

    'editor.close.all': '关闭全部编辑器',

    'opened.editors.title': '打开的编辑器',
    'opened.editors.save.all': '保存全部',
    'opened.editors.close.all': '关闭全部',
    'opened.editors.close.byGroup': '关闭组',
    'opened.editors.save.byGroup': '保存组中未保存文件',
    'opened.editors.empty': '编辑器中暂无打开文件',
    'opened.editors.group.title': '第{0}组',
    'opened.editors.open': '打开文件',
    'opened.editors.openToTheSide': '侧边栏打开',
    'opened.editors.compare': '与当前文件比较',
    'opened.editors.copyRelativePath': '复制相对路径',
    'opened.editors.copyPath': '复制路径',
    'opened.editors.unsaved': '{0} 个未保存',

    'terminal.name': '终端',
    'terminal.disconnected': '终端断开连接',
    'terminal.can.not.create': '创建终端失败',
    'terminal.can.not.reconnect': '终端不可用，请',
    'terminal.stop': '终止终端',
    'terminal.try.reconnect': '重新连接终端',
    'terminal.try.recreate': '重新启动终端',
    'terminal.new': '新建终端',
    'terminal.new.type': '根据终端类型新建',
    'terminal.split': '拆分终端',
    'terminal.clear': '终止所有终端',
    'terminal.clear.content': '清空当前终端内容',
    'terminal.independ': '独立终端',
    'terminal.maximum': '最大化面板大小',
    'terminal.or': '或者',
    'terminal.search': '搜索',
    'terminal.search.next': '搜索下一个匹配项',
    'terminal.openWithPath': '在终端中打开',
    'terminal.remove': '终止终端',
    'terminal.relaunch': '重启终端',
    'terminal.menu.clearGroups': '清除所有终端',
    'terminal.menu.search': '搜索',
    'terminal.menu.split': '拆分终端',
    'terminal.menu.rename': '重命名',
    'terminal.menu.selectAll': '全选',
    'terminal.menu.copy': '复制',
    'terminal.menu.paste': '粘贴',
    'terminal.menu.clear': '清空',
    'terminal.menu.stop': '终止',
    'terminal.menu.stopGroup': '终止终端',
    'terminal.menu.clearCurrentContent': '清空当前终端日志',
    'terminal.menu.selectCurrentContent': '选中当前终端日志',
    'terminal.menu.clearAllContents': '清空所有终端日志',
    'terminal.menu.selectAllContent': '选中所有终端日志',
    'terminal.menu.selectType': '默认终端类型',
    'terminal.menu.moreSettings': '更多设置',
    'terminal.environment.changed': '终端环境变量已经被部分插件修改',
    'terminal.environment.changes': '扩展期望对终端的环境变量做以下更改：',
    'terminal.environment.removal': '扩展期望从现有的终端环境变量中删除以下值：',
    'terminal.launchFail.cwdNotDirectory': '终端启动目录 (cwd) "{0}" 不是一个文件夹',
    'terminal.launchFail.cwdDoesNotExist': '终端启动目录 (cwd) "{0}" 不存在',
    'terminal.launchFail.executableIsNotFileOrSymlink': '终端启动的可执行文件 "{0}" 的路径非文件或符号链接',
    'terminal.launchFail.executableDoesNotExist': '终端启动的可执行文件 "{0}" 的路径不存在',
    'terminal.openFile': '打开文件',
    'terminal.focusFolder': '聚焦资源管理器中的文件夹',
    'terminal.openFolder': '在新窗口中打开文件夹',
    'terminal.toggleTerminal': '切换终端面板',
    'terminal.killProcess': '结束进程',
    'terminal.process.unHealthy': '*此终端会话已被系统超时回收，请打开新的终端会话来进行操作',
    'terminal.selectCWDForNewTerminal': '为新 terminal 选择当前工作路径',

    'view.command.show': '打开 {0}',

    'layout.tabbar.setting': '打开偏好设置',

    'terminal.focusNext.inTerminalGroup': '聚焦当前终端组中的下一个终端',
    'terminal.focusPrevious.inTerminalGroup': '聚焦当前终端组中的上一个终端',

    'terminal.ai.requesting': 'AI 请求中...',
    'terminal.ai.selectHint': '键盘 ↑↓ 键选择命令，⏎ 确认命令',
    'terminal.ai.thinking': 'AI 思考中...',
    'terminal.ai.escClose': '按 ESC 关闭弹框',
    'terminal.ai.headerHint': '使用 AI 获得终端输入提示',
    'terminal.ai.inputHint': '输入自然语言生成命令，示例：展示当前进程 pid',
    'terminal.ai.inputSharpToGetHint': '输入 # 来获取 AI 建议命令',
    'terminal.ai.cannotGetTerminalConnection': '无法获取终端连接，请到用户群反馈',

    'debugger.menu.setValue': '设置变量',
    'debugger.menu.setValue.param': '请输入你要改变变量的值',

    'debugger.menu.copyValue': '复制值',
    'debugger.menu.copyEvaluatePath': '复制表达式',
    'debugger.menu.addToWatchExpressions': '添加到监视',

    'debugger.menu.restartFrame': '重启栈帧',
    'debugger.menu.copyCallstack': '复制堆栈',

    'theme.toggle': '颜色主题',
    'theme.icon.toggle': '文件图标主题',
    'theme.productIcon.toggle': '产品图标主题',

    'theme.base.vs': '浅色主题',
    'theme.base.vs-dark': '深色主题',
    'theme.base.hc': '高对比度主题',
    'theme.current': '当前主题',
    'theme.quickopen.plh': '选择主题（上下移动光标预览）',
    'theme.icon.quickopen.plh': '选择图标主题（上下移动光标预览）',
    'theme.productIcon.quickopen.plh': '选择产品图标主题（上下移动光标预览）',

    'preference.workbench.list.openMode':
      '控制如何使用鼠标展开树和打开列表项（若支持）。如果此设置不适用，某些树和列表可能会选择忽略此设置。',
    'preference.workbench.list.openMode.title': '树展开/列表项打开方式',

    'keymaps.search.placeholder': '输入关键字查找',
    'keymaps.search.keyboard.placeholder': '输入快捷键',
    'keymaps.header.command.title': '命令',
    'keymaps.header.source.title': '作用域',
    'keymaps.header.keybinding.title': '快捷键',
    'keymaps.header.when.title': '条件',
    'keymaps.commandId.title': '命令 ID：{0}',

    'keymaps.source.default': '默认',
    'keymaps.source.user': '用户',
    'keymaps.source.workspace': '工作区',
    'keymaps.keybinding.full.collide': '该快捷键与"{0}"命令冲突，请重新设置',
    'keymaps.keybinding.partial.collide': '该快捷键与"{0}"命令存在包含关系冲突，请重新设置',
    'keymaps.keybinding.shadow.collide': '该快捷键与"{0}"命令存在包含关系冲突，请重新设置',
    'keymaps.keybinding.duplicate': '该快捷键已绑定以下 {0} 条命令：',
    'keymaps.keybinding.loading': '正在修改快捷键 ...',
    'keymaps.keybinding.success': '修改快捷键成功',
    'keymaps.keybinding.fail': '修改快捷键失败',
    'keymaps.keybinding.require': '请输入快捷键',
    'keymaps.action.edit': '编辑快捷键',
    'keymaps.action.add': '添加快捷键',
    'keymaps.action.reset': '重置快捷键',
    'keymaps.action.clear': '清空',
    'keymaps.edit.placeholder': '请按 Enter 键保存',
    'keymaps.editorTitle.openSource': '打开键盘快捷方式(JSON)',
    'keymaps.editorTitle.openKeymap': '打开键盘快捷方式(可视化)',

    'keybinding.combination.tip': '已按下({0}), 等待同时按下第二个键',
    'layout.tabbar.toggle': '切换底部面板显示',
    'layout.tabbar.expand': '最大化底部面板',
    'layout.tabbar.retract': '缩回底部面板',
    'layout.view.hide': '隐藏',
    'layout.action.openView': '打开视图 ...',
    'layout.openView.containerTitle': '侧边栏 / 面板',
    'layout.openView.viewTitle': '{0} / 视图',
    'status-bar.label.line': '行',
    'status-bar.label.column': '列',
    'status-bar.label.selected': '已选择',
    'status-bar.label.tabType.space': '空格',
    'status-bar.label.tabType.tab': 'Tab 大小',
    'status-bar.scm': '源码管理',
    'status-bar.editor-selection': '当前选择',
    'status-bar.editor-language': '选择语言',
    'status-bar.editor-encoding': '选择编码',
    'status-bar.editor-space': '选择空格符',
    'status-bar.editor-eol': '选择行尾字符',
    'status-bar.editor-indentation': '选择缩进',
    'status-bar.editor-langStatus': '语言服务器状态',
    saveChangesMessage: '是否要保存对 {0} 的更改?',
    saveNFilesChangesMessage: '是否保存对以下 {0} 个文件的更改?  \n{1}  \n\n如果不保存，您将会丢失这些文件的所有更改。',
    'file.prompt.more.one': ' 和其他 1 个文件',
    'file.prompt.more.number': ' 和其他 {0} 个文件',
    'file.prompt.dontSave': '不保存',
    'file.prompt.save': '保存',
    'file.prompt.cancel': '取消',

    'doc.saveError.failed': '文件保存失败， 原因:',
    'doc.saveError.diff': '{0} 已经在磁盘上被修改，不能保存',
    'doc.saveError.diffAndSave': '进行比较',
    'doc.saveError.overwrite': '覆盖',
    'editor.compareAndSave.title': '{0} (在磁盘上) <=> {1} (编辑中) ',

    'outline.title': '大纲',
    'outline.noinfo': '在文档中没有找到符号',
    'outline.nomodel': '活动的编辑器无法提供大纲',
    'outline.collapse.all': '折叠全部',
    'outline.sort.kind': '排序依据：类别',
    'outline.sort.name': '排序依据：名称',
    'outline.sort.position': '排序依据：位置',
    'outline.follow.cursor': '跟随光标',

    'welcome.title': '开始使用',
    'welcome.quickStart': '开始使用',
    'welcome.recent.workspace': '最近打开的工作区',
    'welcome.workspace.noExist': '工作区路径不存在',

    'markers.title': '问题',
    'markers.panel.content.empty': '目前尚未在工作区检测到问题。',
    'markers.panel.filter.errors': '错误',
    'markers.panel.filter.warnings': '警告',
    'markers.panel.filter.infos': '信息',
    'markers.filter.placeholder': '筛选器，例如：text、**/*.ts、!**/node_modules/**',
    'markers.filter.content.empty': '在给定的筛选条件下，没有找到结果。',
    'markers.filter.reset': '清除筛选器',
    'markers.status.no.problems': '没有问题',

    'output.channel.clear': '清除输出面板的内容',

    'app.quit': '退出',

    'view.zoomReset': '重置窗口缩放',
    'view.zoomIn': '窗口缩放放大',
    'view.zoomOut': '窗口缩放缩小',

    'extension.invalidExthostReload.confirm.content': '插件进程已失效，点击刷新后可恢复插件使用',
    'extension.invalidExthostReload.confirm.ok': '刷新',
    'extension.invalidExthostReload.confirm.cancel': '继续使用其他功能',
    'extension.crashedExthostReload.confirm': '插件进程异常退出，是否重启插件进程',
    'extension.exthostRestarting.content': '插件进程重启中...',
    'extension.host.restart': '重启插件主进程',

    'extension.no.view.found': '找不到插件 {0} 注册的视图组件。\n 请确保插件中已经导出名为 {1} 的组件。',
    'extension.profilingExtensionHost': '分析扩展主机',
    'extension.profiling.clickStop': '点击停止分析扩展主机',
    'extension.profile.save': '保存扩展宿主分析文件',
    'extension.label': '{0} (扩展)',

    comments: '评论',
    'comments.reply.placeholder': '请输入',
    'comments.participants': '参与者',
    'comments.zone.title': '开始讨论',
    'comments.panel.action.collapse': '全部折叠',
    'comments.thread.action.close': '关闭面板',
    'comments.thread.action.openFile': '打开文件',
    'comments.thread.action.next': '下一个评论',
    'comments.thread.action.previous': '上一个评论',
    'comments.thread.action.reply': '回复',
    'comments.panel.placeholder': '暂无评论',
    'comments.thread.textarea.write': '写',
    'comments.thread.textarea.preview': '预览',

    'treeview.command.action.collapse': '全部折叠',

    'task.outputchannel.name': '任务',
    'task.label': '{0}: {1}',
    'TaskService.pickRunTask': '选择要运行的任务',
    'TerminalTaskSystem.terminalName': '任务 - {0}',
    'terminal.integrated.exitedWithCode': '终端进程已终止，退出代码: {0}',
    'terminal.reuseTerminal': '终端将被任务重用，按任意键关闭。',

    'toolbar-customize.buttonDisplay.description': '按钮展示形式',
    'toolbar-customize.buttonDisplay.icon': '仅图标',
    'toolbar-customize.buttonDisplay.iconAndText': '图标和文字',
    'toolbar-customize.complete': '完成',
    'toolbar.customize.menu': '自定义工具栏...',

    'editor.toggleWordWrap': '切换自动换行',

    'workbench.uploadingFiles': '{0} 个文件上传中 {1}/s',
    'workspace.development.title': '插件开发宿主模式',
    'workbench.testViewContainer': '测试',
    'workbench.hideSlotTabBarWhenHidePanel': '隐藏底部面板时一同隐藏底部标签栏',

    'variable.list.all': '所有已注册的变量',
    'variable.registered.variables': '已注册变量',

    'fileOperation.create': '执行文件创建关联操作中……',
    'fileOperation.delete': '执行文件删除关联操作中……',
    'fileOperation.copy': '执行文件拷贝关联操作中……',
    'fileOperation.move': '执行文件移动关联操作中……',

    'main-layout.left-panel.toggle': '切换左侧面板',
    'main-layout.left-panel.show': '显示左侧面板',
    'main-layout.left-panel.hide': '隐藏左侧面板',
    'main-layout.sidebar.hide': '隐藏左右面板',
    'main-layout.right-panel.toggle': '切换右侧面板',
    'main-layout.right-panel.show': '显示右侧面板',
    'main-layout.right-panel.hide': '隐藏右侧面板',
    'main-layout.bottom-panel.toggle': '切换底部面板',
    'main-layout.bottom-panel.show': '显示底部面板',
    'main-layout.bottom-panel.hide': '隐藏底部面板',

    'refactor-preview.title': '重构预览',
    'refactor-preview.title.clear': '放弃重构',
    'refactor-preview.title.apply': '应用重构',
    'refactor-preview.overlay.title': '正在预览另一个重构。',
    'refactor-preview.overlay.detail': '点击"继续"放弃之前的重构，并继续当前重构。',
    'refactor-preview.overlay.continue': '继续',
    'refactor-preview.overlay.cancel': '取消',
    'refactor-preview.file.create': '新建',
    'refactor-preview.file.delete': '删除',
    'refactor-preview.file.move': '移动',

    'welcome-view.noOpenRepo': '未注册任何源代码管理实现。',
    'welcome-view.noFolderHelp': '没有打开一个目录.\n[打开目录](command:{0})',
    'welcome-view.noLaunchJson':
      '没有检测到调试配置，请先创建。\n[创建调试配置](command:{0})\n\n使用自动生成的调试配置运行\n[查看所有自动调试配置](command:{1})',

    'authentication.manageTrustedExtensions': '管理信任的扩展',
    'authentication.manageExtensions': '选择哪一个账户可以访问这个扩展',
    'authentication.noTrustedExtensions': '该账户没有被任何一个扩展使用',
    'authentication.accountLastUsedDate': '最近使用过该账户',
    'authentication.notUsed': '还未使用过该账户',
    'authentication.signOutMessage': '使用该账户 {0} 的扩展有: \n\n{1}\n\n 是否要退出?',
    'authentication.signOutMessageSimple': '是否要退出 {0}?',
    'authentication.useOtherAccount': '使用其他账户登录',
    'authentication.selectAccount': "扩展 '{0}' 将要访问 {1} 账户",
    'authentication.getSessionPlaceholder': "选择一个账户给 '{0}' 使用",
    'authentication.confirmAuthenticationAccess': "扩展 '{0}' 将要使用 '{2}' 访问 {1}",
    'authentication.confirmLogin': "扩展 '{0}' 将要使用 {1} 登录",
    'authentication.confirmReLogin': "扩展 '{0}' 将要使用 {1} 再次登录",
    'authentication.signInRequests': '登录 {0}',
    'authentication.signOut': '退出 {0}',
    'authentication.noAccounts': '目前没有登录任何账户',
    'authentication.signedOut': '成功退出',
    // refactoring changes related
    'refactoring-changes.ask.1.create': "扩展 '{0} ' 希望通过新建此文件来进行重构更改",
    'refactoring-changes.ask.1.copy': "扩展 '{0} ' 希望通过复制此文件来进行重构更改",
    'refactoring-changes.ask.1.move': "扩展 '{0} ' 希望通过移动此文件来进行重构更改",
    'refactoring-changes.ask.1.delete': "扩展 '{0} ' 希望通过删除此文件来进行重构更改",
    'refactoring-changes.ask.N.create': '{0} 个插件希望通过新建此文件来进行重构更改',
    'refactoring-changes.ask.N.copy': '{0} 个插件希望通过复制此文件来进行重构更改',
    'refactoring-changes.ask.N.move': '{0} 个插件希望通过移动此文件来进行重构更改',
    'refactoring-changes.ask.N.delete': '{0} 个插件希望通过删除此文件来进行重构更改',

    'refactoring-changes.msg.showPreview': '显示预览',
    'refactoring-changes.msg.skipChanges': '跳过更改',

    'keyboard.chooseKeyboardLayout': '选择布局文件配置',
    'keyboard.autoDetect.label': '自动检测',
    'keyboard.autoDetect.description': "（当前：'{0} '）",
    'keyboard.autoDetect.detail': '尝试通过浏览器及按键信息进行布局自动检测',

    // Editor Configurations
    // Only import part of the editor's i18n text, and you can import it according to the needs of the Settings panel
    // ref: https://github.com/microsoft/vscode-loc/blob/main/i18n/vscode-language-pack-zh-hans/translations/main.i18n.json
    'editor.configuration.suggest.filterGraceful': '控制对建议的筛选和排序是否考虑小的拼写错误。',
    'editor.configuration.suggest.insertMode': '控制接受补全时是否覆盖单词。请注意，这取决于扩展选择使用此功能。',
    'editor.configuration.suggest.insertMode.insert': '插入建议而不覆盖光标右侧的文本。',
    'editor.configuration.suggest.insertMode.replace': '插入建议并覆盖光标右侧的文本。',
    'editor.configuration.suggest.localityBonus': '控制排序时是否首选光标附近的字词。',
    'editor.configuration.suggest.maxVisibleSuggestions.dep': '此设置已弃用。现在可以调整建议小组件的大小。',
    'editor.configuration.suggest.preview': '控制是否在编辑器中预览建议结果。',
    'editor.configuration.suggest.shareSuggestSelections':
      '控制是否在多个工作区和窗口间共享记忆的建议选项(需要 `#editor.suggestSelection#`)。',
    'editor.configuration.suggest.showIcons': '控制是否在建议中显示或隐藏图标。',
    'editor.configuration.suggest.showInlineDetails': '控制建议详细信息是随标签一起显示还是仅显示在详细信息小组件中',
    'editor.configuration.suggest.showStatusBar': '控制建议小部件底部的状态栏的可见性。',
    'editor.configuration.suggest.snippetsPreventQuickSuggestions': '控制活动代码段是否阻止快速建议。',
    'editor.configuration.suggestFontSize': '建议小部件的字号。如果设置为 `0`，则使用 `#editor.fontSize#` 的值。',
    'editor.configuration.suggestLineHeight':
      '建议小部件的行高。如果设置为 `0`，则使用 `#editor.lineHeight#` 的值。最小值为 8。',
    'editor.configuration.suggestOnTriggerCharacters': '控制在键入触发字符后是否自动显示建议。',
    'editor.configuration.acceptSuggestionOnEnterSmart': '仅在建议做出文本更改时，才使用 `Enter` 接受建议。',
    'editor.configuration.acceptSuggestionOnEnter':
      '控制编辑器是否应该在 `Tab` 键之外，还使用 `Enter` 键来接受建议。这有助于避免在插入新行或接受建议之间的歧义。',
    'editor.configuration.acceptSuggestionOnCommitCharacter':
      '控制是否在使用提交字符时接受建议。例如，在 JavaScript 中，分号 (`;`) 是一个提交字符，可以接受一个建议并键入该字符。',
    'editor.configuration.snippetSuggestions.top': '将代码片段的建议放在其他建议的顶部。',
    'editor.configuration.snippetSuggestions.bottom': '将代码片段的建议放在其他建议的底部。',
    'editor.configuration.snippetSuggestions.inline': '将代码片段的建议混合在其他建议的中间。',
    'editor.configuration.snippetSuggestions.none': '不展示代码片段建议。',
    'editor.configuration.snippetSuggestions': '控制代码片段是否与其他建议一同显示以及它们的排序方式。',
    'editor.configuration.wordBasedSuggestions': '控制是否根据文档中的字词计算自动补全列表。',
    'editor.configuration.suggestSelection': '控制在建议列表中如何预先选择建议。',
    'editor.configuration.suggestSelection.first': '始终选择第一个建议。',
    'editor.configuration.suggestSelection.recentlyUsed':
      '选择最近的建议，除非进一步键入选择其他项。例如 `console. -> console.log`，因为最近补全过 `log`。',
    'editor.configuration.suggestSelection.recentlyUsedByPrefix':
      '根据之前补全过的建议的前缀来进行选择。例如，`co -> console`、`con -> const`。',
    'editor.configuration.tabCompletion': '启用 Tab 补全。',
    'editor.configuration.tabCompletion.off': '禁用 Tab 补全。',
    'editor.configuration.tabCompletion.on': '在按下 Tab 键时进行 Tab 补全，将插入最佳匹配建议。',
    'editor.configuration.tabCompletion.onlySnippets':
      '在前缀匹配时进行 Tab 补全。在 "quickSuggestions" 未启用时体验最好。',
    'editor.configuration.editor.guides.bracketPairs': '控制是否启用括号对指南。',
    'editor.configuration.editor.guides.bracketPairsHorizontal': '控制是否启用水平括号对指南。',
    'editor.configuration.renderLineHighlight': '控制编辑器的当前行进行高亮显示的方式。',
    'editor.configuration.renderLineHighlight.all': '同时突出显示导航线和当前行。',
    'editor.configuration.renderLineHighlightOnlyWhenFocus': '控制编辑器是否仅在焦点在编辑器时突出显示当前行。',
    'editor.configuration.codeLens': '控制是否在编辑器中显示 CodeLens。',
    'editor.configuration.suggest.showClasss': '启用后，IntelliSense 将显示“类”建议。',
    'editor.configuration.suggest.showColors': '启用后，IntelliSense 将显示“颜色”建议。',
    'editor.configuration.suggest.showConstants': '启用后，IntelliSense 将显示“常量”建议。',
    'editor.configuration.suggest.showConstructors': '启用后，IntelliSense 将显示“构造函数”建议。',
    'editor.configuration.suggest.showCustomcolors': '启用后，IntelliSense 将显示“自定义颜色”建议。',
    'editor.configuration.suggest.showDeprecated': '启用后，IntelliSense 将显示“已启用”建议。',
    'editor.configuration.suggest.showEnumMembers': '启用后，IntelliSense 将显示 "enumMember" 建议。',
    'editor.configuration.suggest.showEnums': '启用后，IntelliSense 将显示“枚举”建议。',
    'editor.configuration.suggest.showEvents': '启用后，IntelliSense 将显示“事件”建议。',
    'editor.configuration.suggest.showFields': '启用后，IntelliSense 将显示“字段”建议。',
    'editor.configuration.suggest.showFiles': '启用后，IntelliSense 将显示“文件”建议。',
    'editor.configuration.suggest.showFolders': '启用后，IntelliSense 将显示“文件夹”建议。',
    'editor.configuration.suggest.showFunctions': '启用后，IntelliSense 将显示“函数”建议。',
    'editor.configuration.suggest.showInterfaces': '启用后，IntelliSense 将显示“接口”建议。',
    'editor.configuration.suggest.showIssues': '启用后，IntelliSense 将显示"问题"建议。',
    'editor.configuration.suggest.showKeywords': '启用后，IntelliSense 将显示“关键字”建议。',
    'editor.configuration.suggest.showMethods': '启用后，IntelliSense 将显示“方法”建议。',
    'editor.configuration.suggest.showModules': '启用后，IntelliSense 将显示“模块”建议。',
    'editor.configuration.suggest.showOperators': '启用后，IntelliSense 将显示“操作符”建议。',
    'editor.configuration.suggest.showPropertys': '启用后，IntelliSense 将显示“属性”建议。',
    'editor.configuration.suggest.showReferences': '启用后，IntelliSense 将显示“参考”建议。',
    'editor.configuration.suggest.showSnippets': '启用后，IntelliSense 将显示“片段”建议。',
    'editor.configuration.suggest.showStructs': '启用后，IntelliSense 将显示“结构”建议。',
    'editor.configuration.suggest.showTexts': '启用后，IntelliSense 将显示“文本”建议。',
    'editor.configuration.suggest.showTypeParameters': '启用后，IntelliSense 将显示 "typeParameter" 建议。',
    'editor.configuration.suggest.showUnits': '启用后，IntelliSense 将显示“单位”建议。',
    'editor.configuration.suggest.showUsers': '启用后，IntelliSense 将显示"用户"建议。',
    'editor.configuration.suggest.showValues': '启用后，IntelliSense 将显示“值”建议。',
    'editor.configuration.suggest.showVariables': '启用后，IntelliSense 将显示“变量”建议。',
    'editor.configuration.suggest.maxVisibleSuggestions':
      '控制 IntelliSense 在显示滚动条之前将显示的建议数量（最多 15 个）。',
    'editor.configuration.suggest.details.visible': '控制编辑器代码补全是否默认展开详情信息',
    'editor.configuration.suggest.filtered':
      '控制是否应从 IntelliSense 中过滤掉某些建议类型。可以在此处找到一份建议类型列表: https://code.visualstudio.com/docs/editor/intellisense#_types-of-completions.',
    'editor.configuration.suggest.filtered.method': '启用后，IntelliSense 将会展示 `method` 类型的建议。',
    'editor.configuration.suggest.filtered.function': '启用后，IntelliSense 将会展示 `function` 类型的建议。',
    'editor.configuration.suggest.filtered.constructor': '启用后，IntelliSense 将会展示 `constructor` 类型的建议。',
    'editor.configuration.suggest.filtered.field': '启用后，IntelliSense 将会展示 `field` 类型的建议。',
    'editor.configuration.suggest.filtered.variable': '启用后，IntelliSense 将会展示 `variable` 类型的建议。',
    'editor.configuration.suggest.filtered.class': '启用后，IntelliSense 将会展示 `class` 类型的建议。',
    'editor.configuration.suggest.filtered.struct': '启用后，IntelliSense 将会展示 `struct` 类型的建议。',
    'editor.configuration.suggest.filtered.interface': '启用后，IntelliSense 将会展示 `interface` 类型的建议。',
    'editor.configuration.suggest.filtered.module': '启用后，IntelliSense 将会展示 `module` 类型的建议。',
    'editor.configuration.suggest.filtered.property': '启用后，IntelliSense 将会展示 `property` 类型的建议。',
    'editor.configuration.suggest.filtered.event': '启用后，IntelliSense 将会展示 `event` 类型的建议。',
    'editor.configuration.suggest.filtered.operator': '启用后，IntelliSense 将会展示 `operator` 类型的建议。',
    'editor.configuration.suggest.filtered.unit': '启用后，IntelliSense 将会展示 `unit` 类型的建议。',
    'editor.configuration.suggest.filtered.value': '启用后，IntelliSense 将会展示 `value` 类型的建议。',
    'editor.configuration.suggest.filtered.constant': '启用后，IntelliSense 将会展示 `constant` 类型的建议。',
    'editor.configuration.suggest.filtered.enum': '启用后，IntelliSense 将会展示 `enum` 类型的建议。',
    'editor.configuration.suggest.filtered.enumMember': '启用后，IntelliSense 将会展示 `enumMember` 类型的建议。',
    'editor.configuration.suggest.filtered.keyword': '启用后，IntelliSense 将会展示 `keyword` 类型的建议。',
    'editor.configuration.suggest.filtered.text': '启用后，IntelliSense 将会展示 `text` 类型的建议。',
    'editor.configuration.suggest.filtered.color': '启用后，IntelliSense 将会展示 `color` 类型的建议。',
    'editor.configuration.suggest.filtered.file': '启用后，IntelliSense 将会展示 `file` 类型的建议。',
    'editor.configuration.suggest.filtered.reference': '启用后，IntelliSense 将会展示 `reference` 类型的建议。',
    'editor.configuration.suggest.filtered.customcolor': '启用后，IntelliSense 将会展示 `customcolor` 类型的建议。',
    'editor.configuration.suggest.filtered.folder': '启用后，IntelliSense 将会展示 `folder` 类型的建议。',
    'editor.configuration.suggest.filtered.typeParameter': '启用后，IntelliSense 将会展示 `typeParameter` 类型的建议。',
    'editor.configuration.suggest.filtered.snippet': '启用后，IntelliSense 将会展示 `snippet` 类型的建议。',
    'editor.configuration.editor.gotoLocation.multiple': '控制 "转到" 命令（例如转到定义）在存在多个目标位置时的行为。',
    'editor.configuration.gotoLocation.multiple.peek': '在速览视图(Peek View)中显示结果。(默认)',
    'editor.configuration.gotoLocation.multiple.gotoAndPeek': '转到主结果并显示速览视图(Peek View)。',
    'editor.configuration.gotoLocation.multiple.goto': '转到主结果，并对其他结果启用无速览导航',
    'editor.configuration.experimental.stickyScroll.enabled': '在编辑器顶部的滚动过程中显示嵌套的当前作用域。',
    'editor.configuration.maxTokenizationLineLength': '由于性能原因，超过这个长度的行将不会被标识。',
    'editor.configuration.codeActionsOnSave.organizeImports': '控制是否应在文件保存时整理导入（Import）语句。',
    'editor.configuration.codeActionsOnSave.fixAll': '控制是否应在文件保存时运行自动修复操作。',
    'editor.configuration.codeActionsOnSave': '在保存时运行的代码操作类型',
    'editor.configuration.codeActionsOnSaveTimeout': '在此超时时间(毫秒)之后代码操作将被取消。',
    'editor.configuration.codeActionsOnSaveNotification': '代码操作执行时是否展示通知信息',
    'editor.configuration.quickSuggestionsDelay': '控制显示智能提示的延迟时长 (毫秒)。',
    'editor.configuration.tabSize':
      '控制 Tab 缩进等于的空格数。若启用 `#editor.detectIndentation#`，该设置可能会被覆盖',
    'editor.configuration.fontWeight': '控制字体粗细，接收 "normal" 和 "bold" 关键词或者 1 到 1000 数值。',
    'editor.configuration.largeFileSize': '控制超大文件的自定义体积。(单位：B)',
    'editor.configuration.preferredFormatter': '配置优先使用的格式化器。',
    'editor.configuration.wrapTab': '控制当编辑器 Tab 超过可用空间时，是否使用换行来代替滚动模式。',
    'editor.configuration.enablePreviewFromCodeNavigation': '控制当代码导航从其出发时，编辑器是否仍处于预览模式。',
    'editor.configuration.formatOnSaveTimeout':
      '控制保存时格式化的超时时间（毫秒）。仅当 `#editor.formatOnSave#` 启用时生效。',
    // inlineSuggest start
    'editor.configuration.inlineSuggest.enabled': '控制是否在编辑器中自动显示内联建议。',
    'editor.configuration.inlineSuggest.showToolbar': '控制何时显示内联建议工具栏。',
    'editor.configuration.inlineSuggest.showToolbar.always': '每当显示内联建议时，就显示内联建议工具栏。',
    'editor.configuration.inlineSuggest.showToolbar.onHover': '将鼠标悬停在内联建议上时显示内联建议工具栏。',
    'editor.configuration.inlineSuggest.showToolbar.never': '永不显示内联建议工具栏。',
    // inlineSuggest end
    'editor.configuration.autoSave': '控制如何自动保存文件。',
    'editor.configuration.formatOnSave': '在保存时格式化文件',
    'editor.configuration.formatOnType': '控制编辑器是在键入后自动格式化该行。',
    'editor.configuration.formatOnPaste':
      '控制编辑器是否自动格式化粘贴的内容。需要当前文件有一个格式化工具并且该格式化格局必须能够在文档中格式化一个范围。',
    'editor.configuration.autoIndent':
      '控制编辑器是否应该在用户键入、粘贴或移动行时自动调整缩进。必须提供带有该语言缩进规则的扩展。',
    'editor.configuration.autoSaveDelay':
      '控制自动保存的延迟时长（毫秒）。仅当 `#editor.autoSave#` 设置为“编辑后自动保存”时生效。',
    'editor.configuration.askIfDiff': '保存文件时如果磁盘上的文件较新，进行提示手动解决保存冲突。',
    'editor.configuration.forceReadOnly': '是否启用 只读模式',
    'editor.configuration.bracketPairColorization.enabled':
      '控制是否启用括号对着色。使用 “workbench.colorCustomizations” 替代括号突出显示颜色。',
    'editor.configuration.guides.bracketPairs': '控制是否启用括号对指南。',
    'editor.configuration.mouseBackForwardToNavigate': '允许使用鼠标按钮 4 和 5 来执行命令“Go Back”和“Go Forward”。',
    'editor.configuration.previewMode': '使用预览模式打开',
    'editor.configuration.tokenColorCustomizations': '覆盖当前所选颜色主题中的编辑器颜色和字体样式',
    'editor.configuration.workbench.editorAssociations':
      '将 glob 模式配置到编辑器(例如 `"*十六进制": "hexEditor.hexEdit"`)。这些优先顺序高于默认行为。',
    'editor.configuration.guides.indentation': '控制编辑器是否显示缩进参考线。',
    'editor.configuration.guides.highlightActiveIndentation': '控制是否突出显示编辑器中活动的缩进参考线。',
    'editor.configuration.trimAutoWhitespace': '删除自动插入的尾随空白符号。',
    'editor.configuration.unicodeHighlight.ambiguousCharacters':
      '控制是否突出显示可能与基本 ASCII 字符混淆的字符，但当前用户区域设置中常见的字符除外。',

    'editor.configuration.lightbulb.enabled': '在编辑器中启用代码操作菜单。',
    'editor.configuration.lightbulb.enabled.off': '禁用代码操作菜单。',
    'editor.configuration.lightbulb.enabled.onCode': '当光标与代码一起排列时，显示代码操作菜单。',
    'editor.configuration.lightbulb.enabled.on': '当光标与代码一起排列或在空的行时，显示代码操作菜单。',

    'diffEditor.configuration.renderSideBySide': '控制差异编辑器的显示方式。',
    'diffEditor.configuration.ignoreTrimWhitespace': '启用后，差异编辑器的前导和尾随空白字符将会忽略',
    'diffEditor.action.toggleCollapseUnchangedRegions': '折叠未更改的代码区域',

    'inlineSuggest.enabled': '控制是否在编辑器中自动显示内联建议。',

    'view.component.renderedError': '视图组件渲染异常',
    'view.component.tryAgain': '重新加载',

    // Testing
    'test.title': '测试管理器',
    'test.result.runFinished': '测试运行完成于 {0}',
    'test.task.unnamed': '未命名任务',
    'test.results': '测试结果',

    // Menu
    'menu.missing.command': '菜单{0} 要执行的命令不存在：{1}',
    'menu.missing.altCommand': '菜单{0} 要执行的命令(altCommand)不存在：{1}',
    'menu.dupe.command': '菜单{0} command({1}) 和 alt({2}) 注册重复',

    'command.category.developerTools': '开发人员工具',

    'connection.start.rtt': '查看通信延迟',
    'connection.stop.rtt': '关闭通信延迟检查',

    'debug.terminal.label': '创建 Javascript Debug Terminal',
    'debug.terminal.title': '调试进程',
    'workbench.action.tasks.runTask': '运行任务',
    'workbench.action.tasks.reRunTask': '执行上次运行的任务',
    'workbench.action.tasks.restartTask': '重新开始运行中的任务',
    'workbench.action.tasks.terminate': '终止任务',
    'workbench.action.tasks.showTasks': '展示任务',
    'workbench.action.tasks.showLog': '展示任务日志',

    'task.contribute': '贡献',
    'task.cannotFindTask': '未找到 {0} 的任务，按回车键返回',

    // Comment
    'comment.reply.count': '{0} 个评论',
    'comment.reply.lastReply': '最后由 {0} 评论',

    // Walkthrough
    'walkthroughs.welcome': '欢迎使用',
    'walkthroughs.get.started': '打开 `入门` 演示',

    // Merge Editor
    'mergeEditor.reset': '重置',
    'mergeEditor.workbench.tab.name': '正在合并: {0}',
    'mergeEditor.conflict.action.apply.confirm.title': '当前文件还有未处理的冲突或变更，是否应用并保存更改？',
    'mergeEditor.conflict.action.apply.confirm.continue': '继续合并',
    'mergeEditor.conflict.action.apply.confirm.complete': '确认保存并更改',
    'mergeEditor.action.button.apply': '应用更改',
    'mergeEditor.action.button.apply-and-stash': '应用并暂存',
    'mergeEditor.action.button.accept.left': '接受左边',
    'mergeEditor.action.button.accept.right': '接受右边',
    'mergeEditor.open.3way': '3-way 编辑器',
    'mergeEditor.conflict.prev': '上一处冲突',
    'mergeEditor.conflict.next': '下一处冲突',
    'mergeEditor.conflict.ai.resolve.all': 'AI 解决',
    'mergeEditor.conflict.ai.resolve.all.stop': '全部停止',
    'mergeEditor.open.tradition': '文本编辑器',
    'workbench.quickOpen.preserveInput': '是否在 QuickOpen 的输入框（包括命令面板）中保留上次输入的内容',

    // #region AI Native
    'aiNative.chat.ai.assistant.name': 'AI 研发助手',
    'aiNative.chat.input.placeholder.default': '可以问我任何问题，或键入主题 "/" ',
    'aiNative.chat.stop.immediately': '我先不想了，有需要可以随时问我',
    'aiNative.chat.error.response': '当前与我互动的人太多，请稍后再试，感谢您的理解与支持',
    'aiNative.chat.code.insert': '插入代码',
    'aiNative.chat.code.copy': '复制代码',
    'aiNative.chat.code.copy.success': '复制成功',
    'aiNative.chat.expand.unfullscreen': '收起',
    'aiNative.chat.expand.fullescreen': '展开全屏',
    'aiNative.chat.enter.send': 'Enter 发送',

    'aiNative.inline.chat.operate.check.title': '采纳',
    'aiNative.inline.chat.operate.thumbsup.title': '赞',
    'aiNative.inline.chat.operate.thumbsdown.title': '踩',
    'aiNative.inline.chat.operate.loading.cancel': '按 ESC 取消',
    'aiNative.inline.chat.input.placeholder.default': '可以问我任何问题，支持 shift + 回车换行',
    'aiNative.inline.hint.widget.placeholder': '按 {0} 唤起 Inline Chat',

    'aiNative.inline.problem.fix.title': 'AI 修复',

    'aiNative.resolve.conflict.dialog.afresh': '你确定要重新生成吗？',
    'aiNative.resolve.conflict.dialog.detection':
      '检测到您已做了修改，重新生成会覆盖掉\n您修改的部分，是否确认进行重新生成。',
    'aiNative.resolve.conflict.message.not.processed.yet':
      'AI 已处理 {0} 处冲突，{1} 处冲突暂未处理（仍标记为黄色部分），需人工处理',

    'aiNative.operate.discard.title': '丢弃',
    'aiNative.operate.afresh.title': '重新生成',
    'aiNative.operate.stop.title': '停止',
    'aiNative.operate.close.title': '关闭',
    'aiNative.operate.clear.title': '清空',

    'aiNative.chat.welcome.loading.text': '初始化中...',

    'preference.ai.native.inlineChat.title': 'Inline Chat',
    'preference.ai.native.chat.title': 'Chat',
    'preference.ai.native.interface.quick.title': '接口快速跳转',
    'preference.ai.native.interface.quick.navigation': '点击编辑器左侧图标快速跳转到接口实现',
    'preference.ai.native.interface.quick.navigation.hover': '跳转到实现',
    'preference.ai.native.inlineChat.auto.visible': '是否在选中代码片段时自动显示 Inline Chat?',
    'preference.ai.native.inlineChat.codeAction.enabled':
      '是否启用在选中代码片段时显示 Inline Chat 相关的 Code Actions?',
    'preference.ai.native.chat.visible.type': '控制 Chat 面板默认的展示方式',
    'preference.ai.native.inlineCompletions.title': '内联补全',
    'preference.ai.native.inlineCompletions.promptEngineering.enabled':
      '是否启用提示词工程，一些 LLM 模型在提示词工程上可能表现不佳。',
    'preference.ai.native.inlineCompletions.debounceTime': '内联补全的延迟时间（毫秒）',
    'preference.ai.native.inlineDiff.preview.mode': 'Inline Diff 的预览模式',
    'preference.ai.native.inlineDiff.preview.mode.sideBySide': '在编辑器当中以左右 diff 面板的方式展示',
    'preference.ai.native.inlineDiff.preview.mode.inlineLive': '在编辑器当中以流式渲染的方式展示',
    'preference.ai.native.inlineCompletions.cache.enabled': '是否启用内联补全的缓存',
    // #endregion AI Native

    'webview.webviewTagUnavailable': '非 Electron 环境不支持 webview 标签，请使用 iframe 标签',

    ...browserViews,
    ...editorLocalizations,
    ...mergeConflicts,
  },
};
